import React from "react";
import { Card } from "react-bootstrap";
import Slider from "react-slick";
import {
  testimonials, testimonialSlider, testimonialCardContainer,
  testimonialCard, testimonialContent, testimonialTitle,
  testimonialDescription, testimonialImage, tcLink
} from "./style.mod.scss";
import getImagePath from "helpers/get-image-path";

const Testimonial = ({ data }) => {

  const { title } = data;

  var settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    arrows: false,
    dots: true,
    centerPadding: "350px",
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          centerMode: true,
          infinite: true,
          centerPadding: "45px",
          slidesToShow: 1,
          slidesToScroll:1,
          initialSlide: 1,
          rows: 1,
          slidesPerRow: 1,
        }
      },
      {
        breakpoint: 1025,
        settings: {
          centerMode: true,
          infinite: true,
          centerPadding: "45px",
          slidesToShow: 1,
          slidesToScroll:1,
          initialSlide: 1,
          rows: 1,
          slidesPerRow: 1,
        }
      },
    ]
  };

  return (
    <div className={testimonials}>
      <Slider {...settings} className={testimonialSlider}>
        {title.map( (tc) => (
          <div key={tc._key} className={testimonialCardContainer}>
            <a className={tcLink} href={tc.link}> 
              <Card className={testimonialCard}>
                <Card.Body className={testimonialContent}>
                  <Card.Title className={testimonialTitle}>
                    {tc.title}
                  </Card.Title>
                  <Card.Img srcSet={getImagePath(tc.logo, true)} alt={tc.logo?.alt} className={testimonialImage}/>
                  <Card.Text className={testimonialDescription}>
                    {tc.content}
                  </Card.Text>
                </Card.Body>
              </Card>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonial;
